import log from "./log";

export function getStoredOrDefault(name: string, value: any) {
  let item = localStorage.getItem(name);
  if (item === null) {
    return value;
  } else {
    return JSON.parse(item);
  }
}

export function store(name: string, value: any) {
  localStorage.setItem(name, JSON.stringify(value));
  log(`Storing ${name} with value: \r\n${value}`)
}
export function clearLocalStorage(){
  localStorage.clear();
}