import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
const App = lazy(() => import('./App.tsx'));
const Disclaimer = lazy(() => import('./components/Disclaimer.tsx'));
const ErrorPage = lazy(() => import('./components/Error.tsx'));
const Privacy = lazy(() => import('./components/Privacy.tsx'));
const BuiltWith = lazy(() => import('./components/BuiltWith.tsx'));
const Station = lazy(() => import('./components/Station.tsx'));

import './index.css';
import { onCLS, onFID, onLCP, onFCP, onINP, onTTFB, Metric, } from 'web-vitals';
import log from './Utilities/log.tsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TourProvider, StepType } from '@reactour/tour';
import { getStoredOrDefault } from './Utilities/storageUtils.tsx';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Suspense><App /></Suspense>,
    errorElement: <ErrorPage />,
  },
  {
    path: "privacy",
    element: <Suspense><Privacy /></Suspense>
  },
  {
    path: "disclaimer",
    element: <Suspense><Disclaimer /></Suspense>
  },
  {
    path: "builtwith",
    element: <Suspense><BuiltWith /></Suspense>
  },
  {
    path: "stats",
    element: <Suspense><BuiltWith /></Suspense>
  },
  {
    path: "station/:id",
    element: <Suspense><Station /></Suspense>
  }
  ,
  {
    path: "station",
    element: <Suspense><Station /></Suspense>
  }
]);

if (import.meta.env.PROD) {
  console.log = () => { };
  console.warn = () => { };
}

function sendToGoogleAnalytics(metric: Metric) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  if (import.meta.env.DEV) {
    log(new Date());
    log(metric);
    return;
  }
  gtag('event', metric.name, {
    // Built-in params:
    value: metric.delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: metric.id, // Needed to aggregate events.
    metric_value: metric.value, // Optional.
    metric_delta: metric.delta, // Optional.
    metric_rating: metric.rating,
    metric_navigationType: metric.navigationType,
    metric_entries: metric.entries
    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/articles/debug-performance-in-the-field
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
}


onCLS(sendToGoogleAnalytics);
onFID(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);
onFCP(sendToGoogleAnalytics);
onINP(sendToGoogleAnalytics);
onTTFB(sendToGoogleAnalytics);
const steps: StepType[] = [
  {
    selector: '#transportMode',
    content: <div>Choose your preferred <u>transportation</u> method</div>,
  }, {
    selector: ".map-container",
    content: "This map shows all current stops for the selected transportation method",
  },
  {
    selector: ".maplibregl-ctrl-geolocate",
    content: <div><b>Click</b> the dot to get current location and matching transportation schedules</div>,
    styles: {
      popover: (base) => ({
        ...base,
        color: "black",
        background: "white",
      })
    }
  },
  {
    selector: "#map-schedules > div:nth-child(1)",
    content: "See the current schedules for stations on the map"
  },
  {
    selector: "#map-schedules > div:nth-child(2)",
    content: "Click on the row to get more information such as trains/buses and times",
    highlightedSelectors: [".modal-content"],
    mutationObservables: [".modal"],
  },
  {
    selector: ".modal",
    content: "View all scheduled transportation on this screen. Enjoy!",
    position: "top",
  }
]

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <TourProvider
      steps={steps}
      styles={{
        popover: (base) => ({
          ...base,
          color: "black",
          background: "white",
          left: "10%"
        }),
        close: (base) => ({
          ...base,
          color: "black"
        })
      }}
      showCloseButton={getStoredOrDefault("reactour", false)} onClickMask={(e) => {
        e.setIsOpen(true);
        e.setCurrentStep(e.currentStep);
      }}
      disableKeyboardNavigation={true}
      disableDotsNavigation={true}
      nextButton={({
        Button,
        currentStep,
        stepsLength,
        setIsOpen
      }) => {
        if (currentStep == 5) {
          let element: HTMLElement = document.getElementsByClassName("stop-row")[0] as HTMLElement;
          if (element) {
            element.click();
          }
        }


        if (currentStep === stepsLength - 1) {
          return <div onClick={() => setIsOpen(false)} >Close</div>
        }
        return (
          <Button />
        )
      }}>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </TourProvider>
  </React.StrictMode>,
)
